/* eslint-disable */
import { useState, useEffect, useRef } from 'react'

function useOnScreen(
  ref,
  { rootMargin = '0px', threshold = 1.0 } = {
    rootMargin: '0px',
    threshold: 1.0
  },
  once = false
) {
  const [isIntersecting, setIntersecting] = useState(false)
  const observer = useRef()

  useEffect(() => {
    if (!ref.current) throw new Error('Must supply a ref to useOnScreen hook.')

    observer.current = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      { threshold, rootMargin }
    )

    observer.current.observe(ref.current)

    return () => {
      ref.current && observer.current?.unobserve(ref.current)
      observer.current?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!isIntersecting) return

    // Disconnect to free resources
    if (once) {
      observer.current?.disconnect()
    }
  }, [isIntersecting])

  return isIntersecting
}

export { useOnScreen }
